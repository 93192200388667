import React, { Component } from 'react'

class Home extends Component {
    render() {
        return (
            <div>
               {/*  ======= Footer ======= */}
                <footer id="footer">
                    <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Norman Lyle Pono</span></strong>
                    </div>
                    </div>
                </footer>{/*  End  Footer */}

                <a href="#header" className="back-to-top"><i className="icofont-simple-up"></i></a>
            </div>
        )
    }
}

export default Home

