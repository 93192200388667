import React, { Component } from 'react'

class Portfolio extends Component {
  render () {
    return (
      <div>
        {/* ======= Portfolio Section ======= */}
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Portfolio</h2>
              <p>These are some screenshots of my past projects. If you have
                questions or doubts if these projects are legit. Please don't
                hesitate to contact me then I can provide you additional
                information or screen share and walkthrough of some of these
                projects. Note: some of these projects may no longer exist on
                the web or my local development. </p>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">All</li>
                  <li data-filter=".filter-larvel">Laravel</li>
                  <li data-filter=".filter-reactjs">React.js</li>
                  {/*<li data-filter=".filter-angularjs">Angular.js</li>*/}
                  <li data-filter=".filter-joomla">Joomla</li>
                  {/*<li data-filter=".filter-wordpress">Wordpress</li>*/}
                  <li data-filter=".filter-net">.NET</li>
                  <li data-filter=".filter-landingpage">Landing Pages</li>
                </ul>
              </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up"
                 data-aos-delay="100">

              {/* LARAVEL */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-larvel">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/laravel_voting_system.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/laravel_voting_system.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Voting System, <b>Category:</b> Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-larvel">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/laravel_yuipco_security_lending.png"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/laravel_yuipco_security_lending.png"
                      data-gall="portfolioGallery" className="venobox"
                      title="<b>Project Name:</b> Yuipco Security Lending System, <b>Category:</b> Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio"
                       title="<b>Project Name:</b> Yuipco Security Lending System, <b>Category:</b> Laravel"><i
                      class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-larvel">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/laravel_partyeasygo.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/laravel_partyeasygo.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Party Easy Go, <b>Category:</b> Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 portfolio-item filter-larvel">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/surigaocitycovid19.info.png"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/surigaocitycovid19.info.png"
                      data-gall="portfolioGallery"
                      className="venobox"
                      title="<b>Project Name:</b> Surigao City Covid19 Information, <b>Category:</b> Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="https://surigaocitycovid19.info"
                       title="Surigao City Covid19 Information" target="_blank"
                       rel="noreferrer"><i
                      className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 portfolio-item filter-larvel">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/nemcobooking.com.png"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/nemcobooking.com.info.png"
                      data-gall="portfolioGallery"
                      className="venobox"
                      title="<b>Project Name:</b> Nemco Booking, <b>Category:</b> Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="https://nemcobooking.com" title="Nemco Booking"
                       target="_blank" rel="noreferrer"><i
                      className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              {/* REACTJS */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-reactjs">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/reactjs_normanpono.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/reactjs_normanpono.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Norman Pono Official Website, <b>Category:</b> React.js"><i
                      className="bx bx-search"></i></a>
                    <a href="https://normanpono.com/" title="" target="_blank"
                       rel="noreferrer"><i class="bx bx-link"
                                           target="_blank"></i></a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 portfolio-item filter-reactjs">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/blueskymedia-tracking_juction.jpg"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/blueskymedia-tracking_juction.jpg"
                      data-gall="portfolioGallery"
                      className="venobox"
                      title="<b>Project Name:</b> Tracking Junction, <b>Company:</b> BlueSkyMedia, <b>Category:</b> ReactJs with Laravel"><i
                      className="bx bx-search"></i></a>
                    <a href="https://trackingjunction.com"
                       title="<b>Project Name:</b> Tracking Junction <b>Company:</b> BlueSkyMedia, <b>Category:</b> ReactJs with Laravel"
                       target="_blank" rel="noreferrer"><i
                      className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              {/* JOOMLA */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_2QS.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_2QS.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Quantum Quest Seminars, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://quantumquestseminars.com/" title=""
                       target="_blank" rel="noreferrer"><i class="bx bx-link"
                                                           target="_blank"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_amsus.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_amsus.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Automated Marketing System, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://www.automatedmarketingsystem.us/" title=""
                       target="_blank" rel="noreferrer"><i class="bx bx-link"
                                                           target="_blank"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_cerarmist.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_cerarmist.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Cerarmist, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://cerarmist.com/" title="" target="_blank"
                       rel="noreferrer"><i class="bx bx-link"
                                           rel="noreferrer"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_chaimreport.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_chaimreport.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Chaim Report, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://www.chaimreport.com/" title=""
                       target="_blank" rel="noreferrer"><i class="bx bx-link"
                                                           rel="noreferrer"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_crytomasterpro.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_crytomasterpro.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Crypto Master Pro, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://cryptomasterpro.com/" title=""
                       target="_blank" rel="noreferrer"><i class="bx bx-link"
                                                           rel="noreferrer"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_duckychallenge.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_duckychallenge.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Ducky Challenge, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://www.duckychallenge.com/" title=""
                       target="_blank" rel="noreferrer"><i
                      class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_dukeduvall.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_dukeduvall.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Light of the World Ministries, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="https://www.dukeduvall.com/" title=""
                       target="_blank" rel="noreferrer"><i
                      class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_foodtabs.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_foodtabs.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Foodtabs, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="http://www.foodtabs.com/" title="" target="_blank"
                       rel="noreferrer"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_sharingblog.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_sharingblog.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Foodtabs, <b>Category:</b> Sharing.Blog"><i
                      className="bx bx-search"></i></a>
                    <a href="https://www.sharing.blog/" title="" target="_blank"
                       rel="noreferrer"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-joomla">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/joomla_uss.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/joomla_uss.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Ultimate Success School, <b>Category:</b> Joomla"><i
                      className="bx bx-search"></i></a>
                    <a href="http://www.ultimatesuccessschool.com/" title=""
                       target="_blank" rel="noreferrer"><i
                      class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              {/*.NET */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-net">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/net_accounting.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/net_accounting.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Malibu Accounting System, <b>Category:</b> .NET"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-net">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/net_cecuritysystemv2.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/net_cecuritysystemv2.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Malibu Security System, <b>Category:</b> .NET"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-net">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/net_pos.png"
                       className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/net_pos.png"
                       data-gall="portfolioGallery" className="venobox"
                       title="<b>Project Name:</b> Malibu Point of Sale System, <b>Category:</b> .NET"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

              {/*.Landing Pages */}
              <div
                className="col-lg-4 col-md-6 portfolio-item filter-landingpage">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/blueskymedia-lp-outputsolar.com.png"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/blueskymedia-lp-outputsolar.com.png"
                      data-gall="portfolioGallery"
                      className="venobox"
                      title="<b>Project Name:</b> Output Solar, <b>Company:</b> BlueSkyMedia, <b>Category:</b> Landing Page"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio" title=""><i
                      className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 portfolio-item filter-landingpage">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/blueskymedia-lp-smartreliefusa.com.png"
                    className="img-fluid" alt=""/>
                  <div className="portfolio-links">
                    <a
                      href="assets/img/portfolio/blueskymedia-lp-smartreliefusa.com.png"
                      data-gall="portfolioGallery"
                      className="venobox"
                      title="<b>Project Name:</b> Smart Relief USA, <b>Company:</b> BlueSkyMedia, <b>Category:</b> Landing Page"><i
                      className="bx bx-search"></i></a>
                    <a href="#portfolio"
                       title="<b>Project Name:</b> Smart Relief USA, <b>Company:</b> BlueSkyMedia, <b>Category:</b> Landing Page"><i
                      className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        {/* End Portfolio Section */}
      </div>
    )
  }
}

export default Portfolio
