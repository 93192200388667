import React, { Component } from 'react'

class ContactUs extends Component {
    render() {
        return (
            <div>
                 {/* ======= Contact Section ======= */}
                 <section id="contact" className="contact section-bg" data-aos="fade-up">
                    <div className="container">
                        <div className="section-title">
                            <h2>How to Reach Me</h2>
                            <p>If you have questions or any inquiries please don't hesitate to contact me information provided below.</p>
                        </div>
                        <div className="row" data-aos="fade-in">
                            <div className="col-lg-12 d-flex align-items-stretch">
                                <div className="row info">
                                    <div className="col-lg-6 email">
                                        <i className="icofont-envelope"></i>
                                        <h4>Email:</h4>
                                        <p><a href="mailto:iostream01@gmail.com">iostream01@gmail.com</a></p>
                                    </div>
                                    <div className="col-lg-6 phone">
                                        <i className="icofont-skype"></i>
                                        <h4>Link:</h4>
                                        <p><a href="https://join.skype.com/invite/YA5odM2keK8v" target="_blank" rel="noreferrer">Norman Pono Skype</a></p>
                                    </div>
                                    <div className="col-lg-6 phone">
                                        <i className="icofont-facebook"></i>
                                        <h4>Link:</h4>
                                        <p><a href="https://www.facebook.com/iostream01/" target="_blank" rel="noreferrer">Norman Pono Facebook</a></p>
                                    </div>
                                    <div className="col-lg-6 phone">
                                        <i className="icofont-linkedin"></i>
                                        <h4>Link:</h4>
                                        <p><a href="https://www.linkedin.com/in/norman-lyle-pono-2178732a/" target="_blank" rel="noreferrer">Norman Pono LinkedIn</a></p>
                                    </div>
                                    {/*
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Contact Section */}     
            </div>
        )
    }
}

export default ContactUs
