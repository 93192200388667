import React, { Component } from 'react'

class WorkExperience extends Component {
  render () {
    return (
      <div>
        {/* ======= Resume Section ======= */}
        <section id="workexperience" className="resume">
          <div className="container">
            <div className="section-title">
              <h2>Work Experience</h2>
              <p>These are my work experience in my web and app development
                career.</p>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Fullstack Developer</h4>
                  <p><em>Company Name:</em> <a
                    href="https://blueskyroi.com/" target="_blank"
                    rel="noreferrer">BLUESKYMEDIA</a></p>
                  <p><em>United States of America</em></p>
                  <p>
                    Full stack developer task frontend and backend. Bug fixing
                    and adding feature
                    as per client request. Creating Land Pages and Custom API.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>Build Landing Pages</li>
                        <li>Wordpress Websites</li>
                        <li>Laravel Applications</li>
                        <li>Tailwind, Boostrap</li>
                        <li>ReactJs, Vue, Alpine</li>
                        <li>API Integration (Facebook/Google)</li>
                        <li>MailChimp, Trackdrive, Ringba</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Application Developed:</strong>
                      <ul>
                        <li><a
                          href="https://trackingjunction.com" target="_blank"
                          rel="noreferrer">Tracking
                          Junction</a> (ReactJs with Laravel)
                        </li>
                        <li>MailChimp API (Laravel)</li>
                        <li>Landing Pages</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Magento 2 and Laravel Developer</h4>
                  <p><em>Company Name:</em> <a
                    href="https://stikadoo.com.ph/" target="_blank"
                    rel="noreferrer">Stikadoo</a></p>
                  <p><em>Surigao City, Philippines</em></p>
                  <p>
                    Working with Magento 2 store. Bug fixing and adding feature
                    as per
                    client request. Have created projects using
                    Laravel as backend API, and a web application using
                    Tailwind, Alpine, Laravel and Livewire
                    (TALL).
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>Magento 2</li>
                        <li>Warden</li>
                        <li>Laravel 9</li>
                        <li>Tailwind</li>
                        <li>Alpine</li>
                        <li>Livewire</li>
                        <li>GitHub</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Application Developed:</strong>
                      <ul>
                        <li><a
                          href="https://www.oxygenconcentratorsupplies.com">Oxygen
                          Concentrator Supplies Shop</a> (Magento 2)
                        </li>
                        <li>Billing System API (Laravel)</li>
                        <li>Inventory System API (Laravel)</li>
                        <li>Amugani.com (TALL)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Homebased Developer</h4>
                  <p><em>Malibu California, United States of America</em></p>
                  <p>
                    Working with ASP.NET. Creating an application from scratch
                    and develop all features as requested.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>ASP.NET</li>
                        <li>C#</li>
                        <li>Visual Studio 2017</li>
                        <li>Mysql</li>
                        <li>Mircrosoft SQL Server</li>
                        <li>Mircrosoft SQL Server Management Studio</li>
                        <li>GitHub</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Application Developed:</strong>
                      <ul>
                        <li>Inventory System</li>
                        <li>Food and Beverages Point of Sale System</li>
                        <li>Malibu Security System</li>
                        <li>Lot Management System</li>
                        <li>Accounting System</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Homebased Web Developer</h4>
                  <p><em>United States</em></p>
                  <p>
                    The first project was "Kanna Cart". It is an e-commerce
                    website build with AngualarJS as the Front-End and Laravel
                    as Backend-End. Both two frameworks are communicated via
                    JSON API.
                    Another project was named "Party Easy Go" it is to keep
                    track of upcoming events. This website is a pure build using
                    Laravel.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>AngualarJS</li>
                        <li>Laravel</li>
                        <li>Vagrant</li>
                        <li>Dockers</li>
                        <li>Bitbucket</li>
                        <li>SCRUM Methodology</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Application Developed:</strong>
                      <ul>
                        <li><a href="https://wf.mrtony.org" target="_blank"
                               rel="noreferrer">Kannacart.com</a></li>
                        <li><a href="http://v2.peg.life" target="_blank"
                               rel="noreferrer">Partyeasygo.com</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Homebased Developer</h4>
                  <p><em>Company Name:</em> Native Commerce(<a
                    href="https://nativecommerce.com/" target="_blank"
                    rel="noreferrer">NC</a>)</p>
                  <p><em>United States</em></p>
                  <p>
                    I was assigned to the Live Team Developers. We fix issues
                    and develop additional custom features for WordPress and
                    Magento that are already done developed by the Developer
                    Team. We also used SCRUM methodology to meet the client
                    deadlines. We also manage to create a mobile application for
                    IOS and Android platforms using IONIC frameworks.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>Wordpress and Magento</li>
                        <li>Create mobile application for IOS & Android using
                          OINIC frameworks
                        </li>
                        <li>Git using Bitbucket</li>
                        <li>SCRUM Methodology</li>
                        <li>Bitbucket & SourceTree</li>
                        <li>Podio</li>
                        <li>Slacks</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Websites Developed:</strong>
                      <ul>
                        <li><a href="http://www.AlfredChurchill.com/"
                               target="_blank"
                               rel="noreferrer">AlfredChurchill.com</a></li>
                        <li><a href="http://www.BigFrigginDeals.com/"
                               target="_blank"
                               rel="noreferrer">BigFrigginDeals.com</a></li>
                        <li><a href="http://www.SurvivalLife.com/"
                               target="_blank"
                               rel="noreferrer">SurvivalLife.com</a></li>
                        <li><a href="http://www.MasonAndIvy.com/"
                               target="_blank"
                               rel="noreferrer">MasonAndIvy.com</a></li>
                        <li><a href="http://www.Mindore.com/" target="_blank"
                               rel="noreferrer">Mindore.com</a></li>
                        <li><a href="http://www.Sewing.com/" target="_blank"
                               rel="noreferrer">Sewing.com</a></li>
                        <li><a href="http://www.ShopWidget.com/" target="_blank"
                               rel="noreferrer">ShopWidget.com</a></li>
                        <li><a href="http://www.SkinCareFreak.com/"
                               target="_blank"
                               rel="noreferrer">SkinCareFreak.com</a></li>
                        <li><a href="http://www.WeenieTees.com/" target="_blank"
                               rel="noreferrer">WeenieTees.com</a></li>
                        <li><a href="http://www.WholesaleDistributor.com/"
                               target="_blank"
                               rel="noreferrer">WholesaleDistributor.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Homebased Web Developer</h4>
                  <p><em>Australia</em></p>
                  <p>
                    Experience working with real state websites.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>Joomla CMS</li>
                        <li>Rackspace Cloud Server</li>
                        <li>Bugherd</li>
                        <li>Bitbucket and SourceTree</li>
                        <li>Manage to build a php script that will generate
                          Properties from XML file data
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Websites Developed:</strong>
                      <ul>
                        <li>PropertyInvestorsonly.com.au</li>
                        <li>PropertyConveyancingDirectory.com.au</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Homebased Web Developer</h4>
                  <p><em>Australia</em></p>
                  <p>
                    Experience working JROX Affiliate Marketing Application.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>JROX</li>
                        <li>Custom features implemented</li>
                        <li>Bug fixes and site maintenance</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Websites Developed:</strong>
                      <ul>
                        <li><a href="http://www.MyMaxRX.com/" target="_blank"
                               rel="noreferrer">MyMaxRX.com</a></li>
                        <li><a href="http://www.GoMaxMedia.com/" target="_blank"
                               rel="noreferrer">GoMaxMedia.com</a></li>
                        <li><a href="http://www.Fm-Broadcasting.net/"
                               target="_blank"
                               rel="noreferrer">Fm-Broadcasting.net</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" data-aos="fade-up"
                   data-aos-delay="100">
                <div className="resume-item">
                  <h4>Web Programmer</h4>
                  <p><em>Company Name:</em> Surigao Internet Marketing (<a
                    href="http://www.surigaointernetmarketing.com"
                    target="_blank" rel="noreferrer">SIM</a>)</p>
                  <p><em>Surigao City, Philippines</em></p>
                  <p>
                    I'm working at Surigao Internet Marketing as Web Programmer
                    with my fellow developers. On my day-to-day task, we develop
                    and implement technical and complex programming to achieve
                    the key features of our goals.
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <strong>Key Experience:</strong>
                      <ul>
                        <li>Create Joomla Components, Module, and Plugins</li>
                        <li>Create Magento extenstion</li>
                        <li>Create custom features for Joomla and Magento</li>
                        <li>Bug fixed, upgrade and update Joomla and Magento
                        </li>
                        <li>Joomla and Magento website Maintenance</li>
                        <li>Linux based Server Maintenance</li>
                        <li>Multi-level Marketing and Affiliate System</li>
                        <li>Used of several linux-based open source application
                          to develop complex web-based systems
                        </li>
                        <li>Working with a small team of web developers with a
                          wide range of skill sets
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <strong>Websites Developed:</strong>
                      <ul>
                        <li><a href="https://quantumquestseminars.com/"
                               target="_blank"
                               rel="noreferrer">QuantumQuestSeminars.com</a>
                        </li>
                        <li><a href="https://www.duckychallenge.com/"
                               target="_blank"
                               rel="noreferrer">Duckychallenge.com</a></li>
                        <li><a href="http://www.Cerarmist.com/" target="_blank"
                               rel="noreferrer">Cerarmist.com</a></li>
                        <li><a href="http://www.DukeDuvall.com/" target="_blank"
                               rel="noreferrer">DukeDuvall.com</a></li>
                        <li><a href="http://www.LifeStyleExpressClub/"
                               target="_blank"
                               rel="noreferrer">LifeStyleExpressClub.com</a>
                        </li>
                        <li><a href="http://www.Sharing.Blog/" target="_blank"
                               rel="noreferrer">Sharing.Blog</a></li>
                        <li><a href="http://www.AutomatedMarketingSystem.us/"
                               target="_blank"
                               rel="noreferrer">AutomatedMarketingSystem.us</a>
                        </li>
                        <li><a href="http://www.MillionaireMindAlliance.com/"
                               target="_blank"
                               rel="noreferrer">MillionaireMindAlliance.com</a>
                        </li>
                        <li><a href="http://www.UltimateSuccessSchool.com/"
                               target="_blank"
                               rel="noreferrer">UltimateSuccessSchool.com</a>
                        </li>
                        <li><a href="http://www.TruthPR.com/" target="_blank"
                               rel="noreferrer">TruthPR.com</a></li>
                        <li><a href="http://www.Mariateben.com/" target="_blank"
                               rel="noreferrer">Mariateben.com</a></li>
                        <li><a href="http://www.Swedishbitters.com/"
                               target="_blank"
                               rel="noreferrer">Swedishbitters.com</a></li>
                        <li><a href="http://www.HerbHealers.com/"
                               target="_blank"
                               rel="noreferrer">HerbHealers.com</a></li>
                        <li><a href="http://www.AlphaOmegaLabs.com/"
                               target="_blank"
                               rel="noreferrer">AlphaOmegaLabs.com</a></li>
                        <li><a href="http://www.Foodtabs.com/" target="_blank"
                               rel="noreferrer">Foodtabs.com</a></li>
                        <li><a href="http://www.Wewillhelpyousell.com/"
                               target="_blank"
                               rel="noreferrer">Wewillhelpyousell.com</a></li>
                        <li><a href="http://www.WildAmateurNight.com/"
                               target="_blank"
                               rel="noreferrer">WildAmateurNight.com</a></li>
                        <li><a href="http://www.iGivefirst.com/" target="_blank"
                               rel="noreferrer">iGivefirst.com</a></li>
                        <li><a href="http://www.UponWingsofAngels.com/"
                               target="_blank"
                               rel="noreferrer">UponWingsofAngels.com</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Resume Section */}
      </div>
    )
  }
}

export default WorkExperience
