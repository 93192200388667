import React, { Component } from 'react'

class Education extends Component {
    render() {
        return (
            <div>
                 {/* ======= Education Section ======= */}
                 <section id="education" className="education section-bg" data-aos="fade-up">
                    <div className="container">
                        <div className="section-title">
                            <h2>Education</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            <table className="table table-bordered table-striped table-hover table-condensed">
                                <thead>
                                    <tr>
                                        <th width="15%">Year</th>
                                        <th width="35%">School</th>
                                        <th>Degree</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2006-2010</td>
                                        <td>
                                        <strong><a href="http://www.ssct.edu.ph/" target="_blank" rel="noreferrer">Surigao State College of Technology (SSCT)</a></strong>
                                        </td>
                                        <td>
                                        <strong>Bachelor of Science in Computer Science</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2000-2004</td>
                                        <td>
                                        <strong><a href="http://spusedu.com" target="_blank" rel="noreferrer">St. Paul University Surigao (SPUS)</a></strong>
                                        </td>
                                        <td>
                                        <strong>Secondary</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Education Section */}
            </div>
        )
    }
}

export default Education
