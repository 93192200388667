import React, { Component } from 'react'

class Skills extends Component {
  render () {
    return (
      <div>
        {/* ======= Skills Section ======= */}
        <section id="skills" className="skills section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Skills</h2>
              <p>I have experience and expertise in these technologies listed
                below.</p>
            </div>
            <div className="row skills-content">
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span
                    className="skill">Linux, Apache, MySQL, and PHP (LAMP) <i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">HTML/XML <i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">CSS <i className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Bootstrap 3,4,5<i
                    className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="90" aria-valuemin="0"
                         aria-valuemax="90"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Tail Wind CSS<i
                    className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="80" aria-valuemin="0"
                         aria-valuemax="80"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">JQuery <i
                    className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="80" aria-valuemin="0"
                         aria-valuemax="80"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">alpinejs <i
                    className="val">50%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="50" aria-valuemin="0"
                         aria-valuemax="50"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Angular <i
                    className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">React.js <i
                    className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="80" aria-valuemin="0"
                         aria-valuemax="80"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Vue <i className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Livewire <i
                    className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="90" aria-valuemin="0"
                         aria-valuemax="90"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Laravel 5,6,7,8,9<i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="90" aria-valuemin="0"
                         aria-valuemax="90"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Version Control(GitHub, BitBucket) <i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Local Dev (Valet,Vagrant,Docker,Warden) <i
                    className="val">70%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="70" aria-valuemin="0"
                         aria-valuemax="70"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">ASP, .NET, C# <i
                    className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Basic Photoshop <i
                    className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">API App Intergrations <i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Joomla <i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Wordpress<i
                    className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="100" aria-valuemin="0"
                         aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Shopify<i
                    className="val">70%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="70" aria-valuemin="0"
                         aria-valuemax="70"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Magento 2<i
                    className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="80" aria-valuemin="0"
                         aria-valuemax="80"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Facebook API Integration<i
                    className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">Google API Integration<i
                    className="val">60%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar"
                         aria-valuenow="60" aria-valuemin="0"
                         aria-valuemax="60"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Skills Section */}
      </div>
    )
  }
}

export default Skills
