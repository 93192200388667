//import logo from './logo.svg';
//import './App.css';

import Home from './components/pages/Home';
import About from './components/pages/About';
import Skills from './components/pages/Skills';
import WorkExperience from './components/pages/WorkExperience';
import Portfolio from './components/pages/Portfolio';
import Education from './components/pages/Education';
import Awards from './components/pages/Awards';
import ContactUs from './components/pages/ContactUs';
import Footer from './components/pages/Footer';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import './assets/vendor/icofont/icofont.min.css';
//import './assets/vendor/boxicons/css/boxicons.min.css';
//import './assets/vendor/venobox/venobox.css';
//import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';
//import './assets/vendor/aos/aos.css';
//import './assets/css/style.css';

//import $ from "jquery";

//import 'jquery/dist/jquery.min.js';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import 'jquery.easing/jquery.easing.min.js';
//import './assets/vendor/php-email-form/validate.js';
//import 'waypoints/lib/jquery.waypoints.min.js';
//import 'counterup/counterup.min.js';
//import 'isotope-layout/dist/isotope.pkgd.min.js';
//import 'venobox/venobox/venobox.js';
//import './assets/vendor/owl.carousel/owl.carousel.min.js';
//import './assets/vendor/typed.js/typed.min.js';
//import './assets/vendor/aos/aos.js';
//import './assets/js/main.js';


//npm install aos 
//npm install bootstrap@4.6.0
//npm install boxicons
//npm install counterup@2.1.0
//npm install @icon/icofont
//npm install isotope-layout@3.0.6 
//npm install jquery@3.5.1 --save
//npm install jquery.easing
//npm install owl.carousel@2.3.4
//npm install php-email-form@2.3
//npm install typed.js@2.0.11
//npm install venobox@1.9.2
//npm install waypoints@4.0.1

import React, { Component } from 'react'

class App extends Component {
    render() {
        return (
          <div>
            <Home />
            <main id="main">
                <About />
                <Skills />
                <WorkExperience />
                <Portfolio />
                <Education />
                <Awards />
                <ContactUs />   
            </main>
            <Footer />
          </div>
        )
    }
}
export default App;
