import React, { Component } from 'react'

class Home extends Component {
  render () {
    return (
      <div>
        {/* ======= Mobile nav toggle button ======= */}
        <button type="button" className="mobile-nav-toggle d-xl-none"><i
          className="icofont-navigation-menu"></i></button>
        {/* ======= Header ======= */}
        <header id="header">
          <div className="d-flex flex-column">
            <div className="profile">
              <img src="assets/img/profile-img.jpg" alt=""
                   className="img-fluid rounded-circle"/>
              <h1 className="text-light"><a href="index.html">Norman Lyle
                Pono</a></h1>
              <div className="social-links mt-3 text-center">
                {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                <a href="https://www.facebook.com/iostream01/" target="_blank"
                   rel="noreferrer" className="facebook"><i
                  className="bx bxl-facebook"></i></a>
                {/* <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a> */}
                <a href="https://join.skype.com/invite/YA5odM2keK8v"
                   className="skype" target="_blank" rel="noreferrer"><i
                  className="bx bxl-skype"></i></a>
                <a href="https://www.linkedin.com/in/norman-lyle-pono-2178732a/"
                   className="linkedin" target="_blank" rel="noreferrer"><i
                  className="bx bxl-linkedin"></i></a>
              </div>
            </div>
            <nav className="nav-menu">
              <ul>
                <li className="active"><a href="index.html#"><i
                  className="bx bx-home"></i> <span>Home</span></a></li>
                <li><a href="#about"><i className="bx bx-user"></i>
                  <span>About</span></a></li>
                <li><a href="#skills"><i className="bx bx-star"></i>
                  <span>Skills</span></a></li>
                <li><a href="#workexperience"><i
                  className="bx bx-file-blank"></i> <span>Work Experience</span></a>
                </li>
                <li><a href="#portfolio"><i
                  className="bx bx-book-content"></i> Portfolio</a></li>
                <li><a href="#education"><i
                  className="bx bx-book"></i> Education</a></li>
                <li><a href="#awards"><i className="bx bx-award"></i> Awards</a>
                </li>
                <li><a href="#contact"><i
                  className="bx bx-envelope"></i> Contact</a></li>
              </ul>
            </nav>
            {/* .nav-menu  */}
            <button type="button" className="mobile-nav-toggle d-xl-none"><i
              className="icofont-navigation-menu"></i></button>
          </div>
        </header>

        {/* ======= Hero Section ======= */}
        <section id="hero"
                 className="d-flex flex-column justify-content-center align-items-center">
          <div className="hero-container" data-aos="fade-in">
            <h1>Norman Lyle Pono</h1>
            <p>I'm a <span className="typed"
                           data-typed-items="Full-Stack Web Developer, Programmer, Freelancer"></span>
            </p>
          </div>
        </section>
        {/* End Hero */}
      </div>
    )
  }
}

export default Home

