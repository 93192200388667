import React, { Component } from 'react'

class Awards extends Component {
    render() {
        return (
            <div>
               {/* ======= Awards Section ======= */}
               <section id="awards" className="awards" data-aos="fade-up">
                    <div className="container">
                        <div className="section-title">
                            <h2>Awards</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            <ul>
                                <li>
                                    <p>
                                        <b>Employee of the Month, Native Commerce</b>
                                        <br/>Certificate of recognition of your dedication, passion and hardwork.
                                        <br/>30th of September, 2016
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>2013 Employee of the Year, Surigao Internet Marketing(SIM)</b> <br/>
                                        Awarded on 24th day of December 2013 For being an Outstanding Employee. 
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Visual Basic Programming</b> <br/>
                                        Certificate of Recognition 1st Place during the IT Days Celebration 2009 with the theme “Harnessing Students Innovation towards Global Competitiveness” 
                                        <br/>Surigao State College of Technology, Surigao City, Philippines
                                        <br/>February 16, 2009
                                    </p>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Awards Section */}
            </div>
        )
    }
}

export default Awards
