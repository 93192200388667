import React, { Component } from 'react'

class About extends Component {
  render () {
    return (
      <div>
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container">

            <div className="section-title">
              <h2>About</h2>
              <ul>
                <li>Object-Oriented PHP / MySQL Web developer</li>
                <li>Highly Experience Web Developer since 2011 until now</li>
                <li>Strong front-end and back-end developer with a wide
                  skillset
                </li>
                <li>Excellent on problem-solving and technical skills</li>
                <li>Quick and flexible adapting to new technologies</li>
                <li>Web research resourceful mindset</li>
                <li>Good Team Player</li>
              </ul>
            </div>

            <div className="row">
              <div className="col-lg-4" data-aos="fade-right">
                <img src="assets/img/profile-img.jpg" className="img-fluid"
                     alt=""/>
              </div>
              <div className="col-lg-8 pt-4 pt-lg-0 content"
                   data-aos="fade-left">
                <h3>Full-Stack Web Developer.</h3>
                <p className="font-italic">
                  Hi, I'm Norman Lyle Pono a Full-Stack Web Developer.
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <ul>
                      <li><i className="icofont-rounded-right"></i>
                        <strong>Birthday:</strong> 20 Nov 1987
                      </li>
                      <li><i className="icofont-rounded-right"></i>
                        <strong>Address:</strong> From Philippines
                      </li>
                      <li><i className="icofont-rounded-right"></i>
                        <strong>Degree:</strong> Bachelor of Science in Computer
                        Science
                      </li>
                      <li><i className="icofont-rounded-right"></i>
                        <strong> Civil Status:</strong> Married
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
        {/* End About Section */}
      </div>
    )
  }
}

export default About
